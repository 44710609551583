import React, { useState } from "react";
import { Slider } from "antd";

export default () => {
  const [height, setHeight] = useState(50);
  const marks = {
    0: (
      <div style={{ whiteSpace: "nowrap" }}>
        <div style={{ whiteSpace: "nowrap" }}>0 cm</div>
        <div>0 ft</div>
      </div>
    ),
    100: (
      <div>
        <div style={{ whiteSpace: "nowrap" }}>3048 cm</div>
        <div style={{ whiteSpace: "nowrap" }}>100 ft</div>
      </div>
    ),
  };
  return (
    <div style={{ display: "flex" }}>
      <div style={{ padding: "48px", width: "100vw" }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          Height:
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div>{height * 30.48} cm /</div>
            <div>{height} ft</div>
          </div>
        </div>

        <Slider marks={marks} onChange={setHeight} value={height} />
      </div>
    </div>
  );
};
